import { getField, updateField } from 'vuex-map-fields'
import moment from 'moment'
import _ from 'lodash'
import requests from '../requests'
import {
  BROADCAST_URL,
  CONTACT_URL,
  UPLOAD_URL,
  REPORTS_URL,
  WHATSAPP_URL,
} from '@/assets/variables/endpoints'

export const state = () => ({
  broadcastLists: {
    data: [],
    meta: {},
  },
  dataTable: [],
  contacts: [],
  broadcast_log: [
    {
      id: '',
      status: '',
    },
  ],
  uploadedFile: {},
  senderLists: [],
  broadcastBuffertTime: 0,
  theBroadcast: {
    detail: null,
    statusCount: null,
    logs: {
      data: [],
      meta: {},
    },
  },
})

export const getters = {
  getField,
}

export const actions = {
  getCampaigns({ commit, state, dispatch }, params) {
    if (params.offset === 1) params.is_counted = true
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'table', status: true },
      { root: true }
    )
    requests.whatsapp
      .getService(`${BROADCAST_URL}/whatsapp`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        async (res) => {
          if (params.offset === 1)
            commit('layouts/SET_TOTAL_COUNT', res.meta.pagination.total, {
              root: true,
            })
          commit('layouts/SET_META', res.meta, { root: true })
          commit('SET_DATATABLE', res.data)
          const campaignIdBulk = []
          await res.data.forEach((data) => {
            campaignIdBulk.push(data.id)
          })
          if (campaignIdBulk.length)
            dispatch('mapCampaignStatus', {
              data: campaignIdBulk,
              type: 'list',
            })
          else
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
        },
        (err) => {
          if (err.error.messages[0].toLowerCase() !== 'expired token') {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
          }
        }
      )
  },
  getContactLists({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .getService(CONTACT_URL, params.parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(
              'infinite/SET_META',
              { data: res.meta, source: params.source },
              { root: true }
            )
            if (params.onlySuccess) {
              const successData = res.data.filter(
                (data) => data.progress === 'success'
              )
              commit('SET_CONTACTS', successData)
              resolve(successData)
            } else {
              commit('SET_CONTACTS', res.data)
              resolve(res.data)
            }
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          }
        )
    })
  },
  getContactListsByPage({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .getService(CONTACT_URL, params.parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(
              'infinite/SET_META',
              { data: res.meta, source: params.source },
              { root: true }
            )
            commit('PUSH_CONTACTS', res.data)
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          }
        )
    })
  },
  getInfiniteContact({ commit, state }, params) {
    requests.whatsapp
      .getService(CONTACT_URL, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('SET_INFINITECONTACTS', res.data)
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        }
      )
  },
  getFindContact({ commit, state }, params) {
    requests.whatsapp
      .getService(CONTACT_URL, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('SET_CONTACTS', res.data)
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        }
      )
  },
  getBroadcastDetail({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${BROADCAST_URL}/${params}/whatsapp`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('SET_BROADCAST_DETAIL', res.data)
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getBroadcastStatusCount({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${REPORTS_URL}/campaign/total-status`,
          { ids: params },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit(
              'SET_BROADCAST_STATUS_COUNT',
              res.data.response[0].message_status_count
            )
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getBroadcastLogLists({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${BROADCAST_URL}/${params.id}/whatsapp/log`,
          params.parameter,
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('SET_BROADCAST_LOG_LISTS', res)
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  createCampaign({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(`${BROADCAST_URL}/whatsapp`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('UPDATE_FILE', {
              url: '',
              name: '',
            })
            resolve(true)
          },
          (err) => {
            reject(err.error.messages[0])
          }
        )
    })
  },
  uploadFile({ commit, state }, params) {
    const myHeaders = new Headers()
    myHeaders.append('Authorization', this.$auth.getToken('hub'))

    const formdata = new FormData()
    formdata.append('folder', params.format)
    formdata.append('file', params.file, params.filename)

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    }

    return new Promise((resolve, reject) => {
      fetch(UPLOAD_URL, requestOptions)
        .then((data) => data.json())
        .then((res) => {
          if (res.status === 'success') {
            commit('UPDATE_FILE', {
              url: res.data.url,
              name: res.data.filename,
            })
          } else {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: res.error.messages[0] || 'Error while uploading file',
                item: '',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
          }
          resolve(true)
        })
        .catch((err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: 'code: ' + err,
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          reject(err)
        })
    })
  },
  logDownload({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${BROADCAST_URL}/${params.id}/whatsapp/log/download`,
          params,
          { Authorization: this.$auth.getToken('hub'), responseType: 'Blob' }
        )
        .subscribe(
          (res) => {
            const url = window.URL.createObjectURL(new Blob([res]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
              'download',
              `Broadcast Log (${
                state.theBroadcast.detail.name
              }) at ${moment().format('DD MMMM YYYY, HH:mm a')}.csv`
            )
            document.body.appendChild(link)
            link.click()
            resolve(true)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  cancelCampaign({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .deleteService(
          `${BROADCAST_URL}/${params}/whatsapp`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  mapCampaignStatus({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .getService(
          `${REPORTS_URL}/campaign/total-status`,
          { ids: params.data },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit(
              params.type === 'list'
                ? 'MAP_CAMPAIGN_STATUS_ON_LIST'
                : 'MAP_CAMPAIGN_STATUS_ON_LOG',
              res.data.response
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          }
        )
    })
  },
  getSenderLists({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .getService(`${WHATSAPP_URL.broadcast_tier}`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('SET_SENDER_LISTS', res.data)
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
          }
        )
    })
  },
  serverStatusCheck({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${WHATSAPP_URL.server_health}`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getBroadcastBuffer({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .getService(`${BROADCAST_URL}/intervals`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('SET_BUFFER_TIME', res.data.waiting_time)
            resolve(res.data.waiting_time)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
          }
        )
    })
  },
  getBroadcastLists({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${BROADCAST_URL}/whatsapp`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            const ids = []
            res.data.forEach((campaign) => {
              ids.push(campaign.id)
            })
            if (ids.length > 0) {
              requests.whatsapp
                .getService(
                  `${REPORTS_URL}/campaign/total-status`,
                  { ids },
                  { Authorization: this.$auth.getToken('hub') }
                )
                .subscribe(
                  (response) => {
                    response.data.response.forEach((status) => {
                      const index = _.findIndex(res.data, { id: status.id })
                      if (index >= 0)
                        res.data[index].message_status_count =
                          status.message_status_count
                    })
                    commit('SET_BROADCAST_LISTS', res)
                    resolve(state.broadcastLists)
                  },
                  (err) => {
                    reject(err)
                  }
                )
            } else {
              commit('SET_BROADCAST_LISTS', res)
              resolve(state.broadcastLists)
            }
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  SET_BROADCAST_LISTS(state, payload) {
    state.broadcastLists.data = payload.data
    state.broadcastLists.meta = payload.meta.pagination
  },
  SET_DATATABLE(state, payload) {
    state.dataTable = payload
  },
  SET_CONTACTS(state, payload) {
    payload = payload.map((obj) => ({ ...obj, value: obj.name }))
    state.contacts = payload
  },
  SET_INFINITECONTACTS(state, payload) {
    state.contacts = state.contacts.concat(payload)
  },
  SET_FILTER(state, payload) {
    state.filters = payload
  },
  UPDATE_BROADCASTLOG(state, payload) {
    state.broadcast_log = payload
  },
  UPDATE_FILE(state, payload) {
    state.uploadedFile = payload
  },
  SET_ID(state, payload) {
    state.ids = payload
  },
  PUSH_CONTACTS(state, payload) {
    payload.forEach((data) => {
      state.contacts.push(data)
    })
  },
  SET_DATATABLE_PARAMETER_BY_INDEX(state, payload) {
    const index = _.findIndex(state.dataTable, { id: payload.id })
    state.dataTable[index][payload.parameter] = payload.value
  },
  MAP_CAMPAIGN_STATUS_ON_LIST(state, payload) {
    payload.forEach((data) => {
      const index = _.findIndex(state.dataTable, { id: data.id })
      if (index >= 0)
        state.dataTable[index].message_status_count = data.message_status_count
    })
  },
  MAP_CAMPAIGN_STATUS_ON_LOG(state, payload) {
    state.broadcastLog.message_status_count = payload[0].message_status_count
  },
  SET_SENDER_LISTS(state, payload) {
    payload = payload.map((obj) => ({
      ...obj,
      value: `${obj.account_name} (${obj.channel_phone})`,
    }))
    state.senderLists = payload
  },
  SET_BUFFER_TIME(state, payload) {
    state.broadcastBuffertTime = payload
  },
  SET_BROADCAST_DETAIL(state, payload) {
    state.theBroadcast.detail = payload
  },
  SET_BROADCAST_STATUS_COUNT(state, payload) {
    state.theBroadcast.statusCount = payload
  },
  SET_BROADCAST_LOG_LISTS(state, payload) {
    state.theBroadcast.logs.data = payload.data
    state.theBroadcast.logs.meta = payload.meta.pagination
  },
}
